/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {

            $(".hamburger").toggleClass('is-active');
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
        });

        $(".sideNav").click(function() {
            removeClass = false;
        });

        $("html").click(function () {
            if (removeClass) {
                $(".hamburger").removeClass('is-active');
                $("#sideNav").removeClass('sideNav-open');
                $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
        });

        $(document).ready(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 100) {
                    $('.scrollup').fadeIn();
                } else {
                    $('.scrollup').fadeOut();
                }
            });
            $('.scrollup').click(function () {
                $("html, body").animate({
                    scrollTop: 0
                }, 600);
                return false;
            });
        });

        $(document).ready(function () {
            function stickFooter() {
                var footer = $(".footer");
                var pos = footer.position();
                var height = $(window).height();
                height = height - pos.top;
                height = height - footer.outerHeight();

                if (height > 0) {
                    footer.css({'margin-top': height + 'px'});
                    console.log("Resizing footer top-margin!");
                }
            }

            stickFooter();
            $(window).resize(function () {
                stickFooter();
            });
        });

        $(document).ready(function() {
            $('.widget_categories .cat-item').has('.children').addClass('has-children');
            $('.widget_categories .has-children > a').after('<span class="expand"><i class="fa fa-angle-down" aria-hidden="true"></i></span>');


            $('.widget_categories .has-children .expand').click(function(e) {
              	e.preventDefault();

                var $this = $(this);

                if ($this.next().hasClass('show')) {
                    $this.next().removeClass('show');
                    $this.next().slideUp(350);

                    $this.removeClass('expanded');
                } else {
                    $this.parent().parent().find('li .inner').removeClass('show');
                    $this.parent().parent().find('li .inner').slideUp(350);
                    $this.next().toggleClass('show');
                    $this.next().slideToggle(350);

                    $this.addClass('expanded');
                }
            });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
          function scrollWords() {
              var $el = $(".home .hero h2"), text = $el.text(),
                  words = text.split(" ");

              var html = "";

              for (var i = 0; i < words.length; i++) {
                  html += "<span>" + words[i] + " </span>";
              }

              $el.html(html).children().css({'opacity': 0}).each(function (i) {
                  $(this).delay(i * 500).animate({
                      opacity: 1
                  }, 700);
              });
          }

          if ($('.home .hero').length) {
              scrollWords();
              setInterval(scrollWords, 15000);
          }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // The Firm page
    'the_firm': {
      init: function() {
          function scrollSentences() {
              var $el = $(".the-firm .hero h2"), text = $el.text(),
                  words = text.split(".");

              var html = "";

              for (var i = 0; i < words.length; i++) {
                  if (!words[i].trim()) {
                    continue;
                  }
                  html += "<span>" + words[i].trim() + ". </span>";
              }

              $el.html(html).children().css({'opacity': 0}).each(function (i) {
                  $(this).delay(i * 500).animate({
                      opacity: 1
                  }, 700);
              });
          }

          if ($('.the-firm .hero').length) {
              scrollSentences();
              setInterval(scrollSentences, 10000);
          }
      }
    },
    // Contact page
    'contact': {
        init: function() {
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
